/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.138.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { StammdatenLogRest } from '../models';
// @ts-ignore
import { StammdatenRest } from '../models';
// @ts-ignore
import { VerfahrensKonfigurationRest } from '../models';
/**
 * VerfahrensKonfigurationRessourceApi - axios parameter creator
 * @export
 */
export const VerfahrensKonfigurationRessourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVerfahrenskonfigurationen: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahrenskonfiguration/datei`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfigurationen.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfiguration.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfiguration.
         * @param {string} codeVerfahrenssteuerung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVerfahrenssteuerung: async (codeVerfahrenssteuerung: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeVerfahrenssteuerung' is not null or undefined
            assertParamExists('getByVerfahrenssteuerung', 'codeVerfahrenssteuerung', codeVerfahrenssteuerung)
            const localVarPath = `/konfigurationen/verfahren/{codeVerfahrenssteuerung}`
                .replace(`{${"codeVerfahrenssteuerung"}}`, encodeURIComponent(String(codeVerfahrenssteuerung)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodelistenKonfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/codelistenkonfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammdatenLogs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrenskonfiguration: async (verfahrenskonfiguration: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenskonfiguration' is not null or undefined
            assertParamExists('uploadStammdatenVerfahrenskonfiguration', 'verfahrenskonfiguration', verfahrenskonfiguration)
            const localVarPath = `/konfigurationen/verfahrenskonfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (verfahrenskonfiguration !== undefined) { 
                localVarFormParams.append('verfahrenskonfiguration', verfahrenskonfiguration as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Stammdaten Verfahrensübersicht in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten Verfahrensübersicht in die K1 hochladen
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] overwriteVerfahren
         * @param {boolean} [deleteAllVerfahren] deleteAllVerfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrensuebersicht: async (verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (overwriteVerfahren !== undefined) {
                localVarQueryParameter['overwriteVerfahren'] = overwriteVerfahren;
            }

            if (deleteAllVerfahren !== undefined) {
                localVarQueryParameter['deleteAllVerfahren'] = deleteAllVerfahren;
            }


            if (verfahrensuebersicht !== undefined) { 
                localVarFormParams.append('verfahrensuebersicht', verfahrensuebersicht as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerfahrensKonfigurationRessourceApi - functional programming interface
 * @export
 */
export const VerfahrensKonfigurationRessourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerfahrensKonfigurationRessourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVerfahrenskonfigurationen(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVerfahrenskonfigurationen(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.downloadVerfahrenskonfigurationen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfigurationen.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensKonfigurationRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfiguration.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfiguration.
         * @param {string} codeVerfahrenssteuerung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrensKonfigurationRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByVerfahrenssteuerung(codeVerfahrenssteuerung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getByVerfahrenssteuerung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodelistenKonfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodelistenKonfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getCodelistenKonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStammdatenLogs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StammdatenLogRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStammdatenLogs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getStammdatenLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.uploadStammdatenVerfahrenskonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Stammdaten Verfahrensübersicht in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten Verfahrensübersicht in die K1 hochladen
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] overwriteVerfahren
         * @param {boolean} [deleteAllVerfahren] deleteAllVerfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.uploadStammdatenVerfahrensuebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerfahrensKonfigurationRessourceApi - factory interface
 * @export
 */
export const VerfahrensKonfigurationRessourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerfahrensKonfigurationRessourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVerfahrenskonfigurationen(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadVerfahrenskonfigurationen(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfigurationen.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<VerfahrensKonfigurationRest>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert VerfahrenKonfiguration.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert VerfahrenKonfiguration.
         * @param {string} codeVerfahrenssteuerung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: any): AxiosPromise<VerfahrensKonfigurationRest> {
            return localVarFp.getByVerfahrenssteuerung(codeVerfahrenssteuerung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodelistenKonfiguration(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCodelistenKonfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammdatenLogs(options?: any): AxiosPromise<Array<StammdatenLogRest>> {
            return localVarFp.getStammdatenLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: any): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Stammdaten Verfahrensübersicht in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Stammdaten Verfahrensübersicht in die K1 hochladen
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] overwriteVerfahren
         * @param {boolean} [deleteAllVerfahren] deleteAllVerfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: any): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerfahrensKonfigurationRessourceApi - object-oriented interface
 * @export
 * @class VerfahrensKonfigurationRessourceApi
 * @extends {BaseAPI}
 */
export class VerfahrensKonfigurationRessourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Download der XML-Verfahrenskonfigurationen als ZIP-Archiv.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public downloadVerfahrenskonfigurationen(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).downloadVerfahrenskonfigurationen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert VerfahrenKonfigurationen.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert VerfahrenKonfigurationen.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getAll(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert VerfahrenKonfiguration.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert VerfahrenKonfiguration.
     * @param {string} codeVerfahrenssteuerung 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getByVerfahrenssteuerung(codeVerfahrenssteuerung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getCodelistenKonfiguration(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getCodelistenKonfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getStammdatenLogs(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getStammdatenLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Stammdaten (Verfahrenskonfiguration/Aufgaben/Verfahrensübersicht) in die K1 hochladen
     * @param {File} verfahrenskonfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Stammdaten Verfahrensübersicht in die K1 hochladen.  <p>  Tickets BSW-151, BSW-207, BSW-205</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Stammdaten Verfahrensübersicht in die K1 hochladen
     * @param {File} [verfahrensuebersicht] 
     * @param {boolean} [overwriteVerfahren] overwriteVerfahren
     * @param {boolean} [deleteAllVerfahren] deleteAllVerfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options).then((request) => request(this.axios, this.basePath));
    }
}

