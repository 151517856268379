/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.138.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { MonitoringStatusRest } from '../models';
/**
 * MonitoringRessourceApi - axios parameter creator
 * @export
 */
export const MonitoringRessourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Liefert den Monitoring-Status.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Liefert den Monitoring-Status.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringState: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getMonitoringState', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/monitoring/status`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktiviert bzw. deaktiviert das Monitoring.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktiviert bzw.
         * @param {MonitoringStatusRest} monitoringStatusRest uebermittelter MonitoringStatus.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMonitoringState: async (monitoringStatusRest: MonitoringStatusRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitoringStatusRest' is not null or undefined
            assertParamExists('setMonitoringState', 'monitoringStatusRest', monitoringStatusRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setMonitoringState', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/monitoring/status`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitoringStatusRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitoringRessourceApi - functional programming interface
 * @export
 */
export const MonitoringRessourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitoringRessourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert den Monitoring-Status.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Liefert den Monitoring-Status.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonitoringState(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitoringStatusRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonitoringState(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MonitoringRessourceApi.getMonitoringState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktiviert bzw. deaktiviert das Monitoring.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktiviert bzw.
         * @param {MonitoringStatusRest} monitoringStatusRest uebermittelter MonitoringStatus.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMonitoringState(monitoringStatusRest: MonitoringStatusRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitoringStatusRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMonitoringState(monitoringStatusRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MonitoringRessourceApi.setMonitoringState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MonitoringRessourceApi - factory interface
 * @export
 */
export const MonitoringRessourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitoringRessourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert den Monitoring-Status.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Liefert den Monitoring-Status.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringState(planID: string, options?: any): AxiosPromise<MonitoringStatusRest> {
            return localVarFp.getMonitoringState(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktiviert bzw. deaktiviert das Monitoring.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktiviert bzw.
         * @param {MonitoringStatusRest} monitoringStatusRest uebermittelter MonitoringStatus.
         * @param {string} planID planId des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMonitoringState(monitoringStatusRest: MonitoringStatusRest, planID: string, options?: any): AxiosPromise<MonitoringStatusRest> {
            return localVarFp.setMonitoringState(monitoringStatusRest, planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitoringRessourceApi - object-oriented interface
 * @export
 * @class MonitoringRessourceApi
 * @extends {BaseAPI}
 */
export class MonitoringRessourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Liefert den Monitoring-Status.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Liefert den Monitoring-Status.
     * @param {string} planID planId des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringRessourceApi
     */
    public getMonitoringState(planID: string, options?: RawAxiosRequestConfig) {
        return MonitoringRessourceApiFp(this.configuration).getMonitoringState(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktiviert bzw. deaktiviert das Monitoring.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Aktiviert bzw.
     * @param {MonitoringStatusRest} monitoringStatusRest uebermittelter MonitoringStatus.
     * @param {string} planID planId des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringRessourceApi
     */
    public setMonitoringState(monitoringStatusRest: MonitoringStatusRest, planID: string, options?: RawAxiosRequestConfig) {
        return MonitoringRessourceApiFp(this.configuration).setMonitoringState(monitoringStatusRest, planID, options).then((request) => request(this.axios, this.basePath));
    }
}

